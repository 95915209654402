<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="servicePointsList"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :server-items-length="getServicePointsTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Торгівельні точки</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
            ></v-text-field>

            <v-dialog v-model="dialog" max-width="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="forceRerender" color="primary" small v-bind="attrs" v-on="on">Додати торгівельну точку</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <PagingSelect
                          :value="editedItem.balance_holder"
                          :options="balanceHolders"
                          :options-meta="balanceHoldersMeta"
                          :options-params="balanceHoldersOptions"
                          item-value="id"
                          item-text="name"
                          label="Орендодавець"
                          @change="(value) => (editedItem.balance_holder = value)"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <div>
                          <v-text-field
                            v-model="editedItem.name"
                            label="Назва"
                            @input="$v.editedItem.name.$touch()"
                            @blur="$v.editedItem.name.$touch()"
                            :error-messages="nameErrors"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <AddressAutocomplete
                          v-if="isComponentRerendered"
                          ref="addressAutocomplete"
                          @updateItemsList="updateItemsList"
                          :entity-id="servicePoint.id"
                          :addressId="clickedAddressId"
                          :is-data-saved="isDataSaved"
                          type="servicePoint"
                          :key="addressAutocompleteKey"
                        ></AddressAutocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="8" md="8">
                        <v-text-field v-model="editedItem.schedule" label="Графік роботи"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <v-select
                          v-model="editedItem.availability"
                          :items="availItems"
                          item-text="text"
                          item-value="value"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea v-model="editedItem.description" label="Опис" dense hide-details></v-textarea>
                      </v-col>
                    </v-row>
                    <!--                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model.number="editedItem.latitude" label="Широта"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model.number="editedItem.longitude" label="Довгота"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model.number="editedItem.habitants_count" label="Кількість жителів"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model.number="editedItem.floor_count" label="Кількість поверхів"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="editedItem.location.length >= 2">
                      <v-col v-for="(item, index) in editedItem.location" :key="index" cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.location[index]"
                          label="Місце"
                          append-outer-icon="mdi-plus"
                          @click:append-outer="addLocation"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.location[0]"
                          label="Місце"
                          append-outer-icon="mdi-plus"
                          @click:append-outer="addLocation"
                        ></v-text-field>
                      </v-col>
                    </v-row>-->
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Точку буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <template>
            <router-link
              :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: item.id } }"
              class="text-decoration-none"
            >
              <v-tooltip bottom :open-on-focus="false">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-if="item.status !== null && item.status !== 1"
                    :style="{ color: getListStatusColor(item.status) }"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.id }}
                  </span>
                  <span v-else v-bind="attrs" v-on="on">{{ item.id }}</span>
                </template>
                <span>{{ getListStatusTitle(item.status) }}</span>
              </v-tooltip>
            </router-link>
          </template>
        </template>
        <template v-slot:item.address="{ item }">
          <template>
            <router-link
              v-if="item.address"
              :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: item.id } }"
              class="text-decoration-none"
            >
              {{ item.address.country }}, {{ item.address.city }}, {{ item.address.street }},
              {{ item.address.building_number }}
            </router-link>
          </template>
        </template>
        <template v-slot:item.location="{ item }">
          <template v-if="item.location.length">
            <div v-for="(location, index) in item.location" :key="index" class="mt-2 mb-2">{{ location }}</div>
          </template>
          <template v-else>
            <span class="font-weight-thin font-italic">не підключено</span>
          </template>
        </template>
        <template v-slot:item.terminals="{ item }">
          <template v-if="item.terminals.length">
            <div v-for="terminal in item.terminals" :key="terminal.id" class="mt-2 mb-2">
              <router-link
                :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: terminal.id } }"
                class="text-decoration-none"
              >
                T {{ terminal.id }}
              </router-link>
            </div>
          </template>
          <template v-else>
            <span class="font-weight-thin font-italic">не підключено</span>
          </template>
        </template>
        <template v-slot:item.balance_holder.name="{ item }">
          <template>
            <router-link
              v-if="item.balance_holder"
              :to="{ name: 'PartnerCard', params: { id: currentCompanyId, partnerId: item.balance_holder.id } }"
              class="text-decoration-none"
            >
              {{ item.balance_holder.name }}
            </router-link>
            <span v-else class="red--text">Відкріплено</span>
          </template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on"> mdi-pencil-outline </v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Видалити</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="navigateToServicePointCard(item)" v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { SERVICE_POINTS_SCHEMA } from '@/const/apiSchemas'
import { mergeObjects } from '@/helpers/mergeObjects'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import resetTableData from '@/mixins/resetTableData'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import ForceRerender from '@/mixins/forceRerender'
import UpdateItemsList from '@/mixins/updateItemsList'
import BackButton from '@/components/common/BackButton'
import sortUtils from '@/mixins/sortUtils'
import cloneDeep from '@/mixins/cloneDeep'

export default {
  name: 'ServicePointsList',

  components: {
    BackButton,
    PagingSelect: () => import('@/components/common/PagingSelect'),
    AddressAutocomplete: () => import('@/components/partials/AddressAutocomplete'),
  },

  mixins: [validationMixin, resetTableData, ConvertStatusesTypes, ForceRerender, UpdateItemsList, sortUtils, cloneDeep],

  validations: {
    editedItem: {
      name: { required },
    },
  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: '',
    loading: false,
    servicePoint: {},
    clickedServicePointId: null,
    clickedAddressId: null,
    headers: [
      { text: 'ID', sortable: true, value: 'id' },
      { text: 'Назва', value: 'name', sortable: true },
      { text: 'Адреса', value: 'address', sortable: false },
      { text: 'Розташування', value: 'location', sortable: false },
      { text: 'Термінали', value: 'terminals', sortable: false },
      { text: 'Орендодавець', value: 'balance_holder.name', sortable: false },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'center' },
    ],
    servicePointsList: [],
    editedIndex: -1,
    isDataSaved: false,
    sortBy: '',
    sortDesc: false,
    options: {},
    editedItem: {},
    defaultItem: { ...SERVICE_POINTS_SCHEMA },
    addressAutocompleteKey: 0,
    availItems: [
      { text: 'Закрито', value: false },
      { text: 'Відкрито', value: true },
    ],
    balanceHoldersOptions: null,
  }),

  computed: {
    ...mapGetters('servicePoints', ['getServicePointsTotalCount']),
    ...mapState('servicePoints', ['servicePoints']),
    ...mapState('partners', ['balanceHolders', 'balanceHoldersMeta']),

    formTitle() {
      return this.editedIndex === -1 ? 'Створити торгівельну точку' : 'Редагувати торгівельну точку'
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('Це поле обов"язкове')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('servicePoints', [
      'loadServicePoints',
      'createNewServicePoint',
      'updateSelectedServicePoint',
      'deleteSelectedServicePoint',
    ]),
    ...mapActions('addresses', ['deleteAddress']),
    ...mapActions('partners', ['loadBalanceHolders']),
    ...mapActions('logs', ['displayWarningAlert']),

    async initialize() {
      this.editedItem = this.cloneObjectDeep(SERVICE_POINTS_SCHEMA)
      this.defaultItem = this.cloneObjectDeep(SERVICE_POINTS_SCHEMA)
      this.servicePointsList = [...this.servicePoints]
      this.balanceHoldersOptions = {
        loadingFunction: this.loadBalanceHolders,
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
        },
      }
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const params = {
          company: this.currentCompanyId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
        }
        await this.loadServicePoints(params)
        await this.initialize()
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },

    editItem(item) {
      this.servicePoint = { ...item }
      this.editedIndex = this.servicePointsList.indexOf(item)
      this.editedItem = mergeObjects(this.editedItem, item)
      this.clickedServicePointId = item.id
      this.clickedAddressId = item.address?.id
      this.forceRerender()
      this.dialog = true
    },

    /*addLocation() {
      this.editedItem.location.push('')
    },*/

    deleteItem(item) {
      this.editedIndex = this.servicePointsList.indexOf(item)
      this.clickedServicePointId = item.id
      this.clickedAddressId = item.address?.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        servicePointId: this.clickedServicePointId,
      }
      const deleteAddressPayload = {
        type: 'servicePoint',
        companyId: this.currentCompanyId,
        servicePointId: this.clickedServicePointId,
        addressId: this.clickedAddressId,
      }
      await Promise.all([this.deleteSelectedServicePoint(payload), this.deleteAddress(deleteAddressPayload)])
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },

    close() {
      this.dialog = false
      this.addressAutocompleteKey++
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.clickedServicePointId = null
        this.clickedAddressId = null
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.clickedServicePointId = null
        this.clickedAddressId = null
      })
    },

    async save() {
      this.$refs.addressAutocomplete.handleButtonClicked()
      if (this.$refs.addressAutocomplete.isAddressEmpty || !this.$refs.addressAutocomplete.editedItem.building_number) return
      this.loading = true
      if (this.editedIndex > -1) {
        const payload = {
          companyId: this.currentCompanyId,
          servicePointId: this.clickedServicePointId,
          updatedServicePoint: this.editedItem,
        }
        await this.updateSelectedServicePoint(payload)
      } else {
        const payload = {
          companyId: this.currentCompanyId,
          newServicePoint: this.editedItem,
        }
        await this.createNewServicePoint(payload)
      }
      this.isDataSaved = true
    },

    navigateToServicePointCard(item) {
      const clickedServicePointId = item.id
      this.$router.push({
        name: 'ServicePointCard',
        params: { id: this.currentCompanyId.toString(), servicePointId: clickedServicePointId.toString() },
      })
    },
  },
}
</script>

<style scoped></style>
